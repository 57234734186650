<template>
  <el-dialog
    title="修改密码"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
     <el-form-item label="密码：" prop="passwordHash">
        <el-input
          v-model="modalData.passwordHash"
          placeholder="请输入密码"
          :maxLength="50"
        ></el-input>
      </el-form-item>

    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { areas } from "@/common/area.js";

export default {
  mixins: [modalMixin],
  emits: ['submit'],
  props: ["modalData", "modalType"],
  data() {
    return {
      
      formName: "form",
      ruleValidate: {
         nickName: [
          {
            required: true,
            message: "请输入昵称不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.validateForm().then((res) => {
        let obj ={
          id: this.modalData.id,
          passwordHash: this.modalData.passwordHash,
          isAdmin: 0
        }

        this.$emit("submit", obj);
      });
    },
  },
};
</script>
