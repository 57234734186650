<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4"> </el-col>
    </el-row>
    <div>
      <el-button
        class="but"
        type="primary"
        @click="showAddUser"
        v-auth="this.per.SYS_USER_ADD"
        >添加账户</el-button
      >
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="昵称" prop="nickName"></el-table-column>
      <el-table-column
        label="角色"
        prop="roleName"
      ></el-table-column>
      <el-table-column label="用户名" prop="userName"></el-table-column>
      <el-table-column label="手机号码" prop="phone"></el-table-column>
      <el-table-column label="邮箱" prop="email"></el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEditUser(scope.row)"
            v-auth="this.per.SYS_USER_UPDATA"
          >
            修改
          </span>
<!-- 
          <span
            class="option option-primary"
            v-auth="this.per.SYS_USER_UPDATA"
            @click="showUpdateRole(scope.row)"
          >
            设置角色
          </span> -->

          <span
            class="option option-primary"
            v-auth="this.per.SYS_USER_UPDATA"
            @click="showUpdatePwd(scope.row)"
          >
            修改密码
          </span>

          <!-- <span
            class="option option-danger"
            @click="doDel(scope.row)"
            v-auth="this.per.SYS_USER_DEL"
            >删除</span
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :roleList="roleList"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitUser"
    ></EditModal>

    <EditRoleModal
      :roleList="roleList"
      :modalData="modalData"
      @submit="updateRole"
      :ref="MODAL_KEY.ROLE_MODAL"
    ></EditRoleModal>

    <UpdatePwdModal
      @submit="doUpdatePwd"
      :modalData="modalData"
      :ref="MODAL_KEY.UPDATE_MODAL"
    ></UpdatePwdModal>
  </div>
</template>

<script>
import {
  fetchUserList,
  addUser,
  updateUser,
  delUser,
  updateUserRole,
  updatePwd,
} from "@/api/user";
import { fetchRoleListAll } from "@/api/permission";
import EditModal from "./component/EditModal";
import EditRoleModal from "./component/EditRoleModal";
import { areas } from "@/common/area.js";
import UpdatePwdModal from "./component/UpdatePwdModal.vue";
export default {
  components: { EditModal, EditRoleModal, UpdatePwdModal },
  data() {
    return {
      
      dataSource: [],
      query: {
        lastTime: "",
        address: "",
        lastIp: "",
        nickName: "",
        updateAt: "",
        userName: "",
        createAt: "",
        startTime: "",
        face: "",
        phone: "",
        email: "",
        operationId: '',
        roleType: this.const.ROLE_TYPE_CODE.SYS_MANAGE,
        openid: "",
        unionid: "",
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        ROLE_MODAL: "ROLE_MODAL",
        EDIT_MODAL: "EDIT_MODAL",
        UPDATE_MODAL: "UPDATE_MODAL",
      },
      roleList: [],
      total: 0,
    };
  },
  methods: {
    showUpdatePwd(data) {
      this.modalData = this.deepClone(data);
      this.$refs[this.MODAL_KEY.UPDATE_MODAL].showModal();
    },
    doUpdatePwd(data) {
      updatePwd(data).then((res) => {
        this.$message.success("修改成功");
        this.getList();
        this.$refs[this.MODAL_KEY.UPDATE_MODAL].closeModal();
      });
    },

    showUpdateRole(data) {
      this.modalData = this.deepClone(data);
      this.$refs[this.MODAL_KEY.ROLE_MODAL].showModal();
    },

    // 添加角色
    updateRole(data) {
      updateUserRole(data).then((res) => {
        this.$message.success("角色修改成功！");
        this.getList();
        this.$refs[this.MODAL_KEY.ROLE_MODAL].closeModal();
      });
    },

    showAddUser() {
      this.modalData = {
        operationId: 0,
        stationId: 0,
      };
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    showEditUser(data) {
      this.modalData = this.deepClone(data);
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    submitUser(data) {
      let request = updateUser;
      let msg = "编辑用户成功";
      if (this.validatenull(data.id)) {
        request = addUser;
        msg = "添加用户成功";
        data.roleType = this.const.ROLE_TYPE_CODE.SYS_MANAGE;
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    // 删除用户
    doDel(e) {
      this.$confirm("是否要删除用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        delUser({ id: e.id }).then((res) => {
          this.getList();
          this.$message.success("删除成功");
        });
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUserList({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    getRoleAll() {
      fetchRoleListAll().then((res) => {
        this.roleList = res.data.data.records;
      });
    },
  },

  mounted() {
    this.getRoleAll();
    this.getList();
  },
};
</script>
